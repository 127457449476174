

const ContentsFooter = () => (
    <div className="content__footer">
      <div className="assure-defi">
        <img  src={process.env.PUBLIC_URL + "/images/assure-defi.png"} alt=""/>
      </div>
      <p>
        MADE BY SPREDICT WITH passion <br /> Copyright © 2025 All rights reserved.
      </p>
    </div>
  );

export default ContentsFooter;