import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { WagmiProvider } from "wagmi";
import { ConnectKitProvider } from "connectkit";
import { config } from "./Components/WagmiConfig"
import Main from "./Components/Main";

const queryClient = new QueryClient();

export default function App() {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <ConnectKitProvider>
          <Main />
        </ConnectKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
}
