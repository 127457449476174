export const INVALID_INDEX = 999_999_999_999_999; // max supported decimals

export const CATEGORY_NAME = "MMA";

export const mmaPoolData = [
  {
    title: "ROUND 1 - UFC 311",
    when: "19 Jan 4AM UTC",
    tooltipText: "ROUND COMPLETED",
    index: 0,
    participants: [
      {
        id: "1",
        pool: "A",
        weight: "Lightweight",
        eventType: "main event",
        fighter1: {
          name: "Makhachev",
          no: "C",
          code: "1",
          flag: "RU",
          favorite: true,
          probability: "89%",
        },
        fighter2: {
          name: "Moicano",
          no: "10",
          code: "2",
          flag: "BR",
          favorite: false,
          probability: "11%",
        },
      },
      {
        id: "2",
        pool: "B",
        weight: "Bantamweight",
        eventType: "",
        fighter1: {
          name: "Dvalishvili",
          no: "C",
          code: "3",
          flag: "GE",
          favorite: true,
          probability: "64%",
        },
        fighter2: {
          name: "Nurmagomedov",
          no: "02",
          code: "4",
          flag: "RU",
          favorite: false,
          probability: "36%",
        },
      },
      {
        id: "3",
        pool: "C",
        weight: "Light Heavyweight",
        eventType: "",
        fighter1: {
          name: "Procházka",
          no: "02",
          code: "5",
          flag: "CZ",
          favorite: false,
          probability: "42%",
        },
        fighter2: {
          name: "Hill",
          no: "03",
          code: "6",
          flag: "US",
          favorite: true,
          probability: "58%",
        },
      },
      {
        id: "4",
        pool: "D",
        weight: "Heavyweight",
        eventType: "",
        fighter1: {
          name: "Almeida",
          no: "06",
          code: "7",
          flag: "BR",
          favorite: false,
          probability: "31%",
        },
        fighter2: {
          name: "Spivac",
          no: "07",
          code: "8",
          flag: "MD",
          favorite: true,
          probability: "69%",
        },
      },
    ]
  },
  {
    title: "ROUND 1 - UFC 312",
    when: "Sun Jan 9 3AM UTC",
    tooltipText: "ROUND COMPLETED",
    index: 1,
    participants: [
      {
        id: "1",
        pool: "A",
        weight: "Middleweight",
        eventType: "main event",
        fighter1: {
          name: "Du Plessis",
          no: "C",
          code: "1",
          flag: "ZA",
          favorite: true,
          probability: "89%",
        },
        fighter2: {
          name: "Strickland",
          no: "01",
          code: "2",
          flag: "US",
          favorite: false,
          probability: "11%",
        },
      },
      {
        id: "2",
        pool: "B",
        weight: "Women Strawweight",
        eventType: "",
        fighter1: {
          name: "Zhang",
          no: "C",
          code: "3",
          flag: "CN",
          favorite: true,
          probability: "64%",
        },
        fighter2: {
          name: "Suarez",
          no: "01",
          code: "4",
          flag: "US",
          favorite: false,
          probability: "36%",
        },
      },
      {
        id: "3",
        pool: "C",
        weight: "Heavyweight",
        eventType: "",
        fighter1: {
          name: "Tafa",
          no: "NR",
          code: "5",
          flag: "NZ",
          favorite: false,
          probability: "42%",
        },
        fighter2: {
          name: "Teixeira",
          no: "NR",
          code: "6",
          flag: "BR",
          favorite: true,
          probability: "58%",
        },
      },
      {
        id: "4",
        pool: "D",
        weight: "Light Heavyweight",
        eventType: "",
        fighter1: {
          name: "Crute",
          no: "NR",
          code: "7",
          flag: "AU",
          favorite: false,
          probability: "31%",
        },
        fighter2: {
          name: "Bellato",
          no: "NR",
          code: "8",
          flag: "BA",
          favorite: true,
          probability: "69%",
        },
      },
    ]
  },
  {
    title: "ROUND 1 - UFC FIGHT NIGHT",
    when: "Sun Feb 16 12AM UTC",
    tooltipText: "ROUND COMPLETED",
    index: 2,
    participants: [
      {
        id: "1",
        pool: "A",
        weight: "Middleweight",
        eventType: "main event",
        fighter1: {
          name: "Cannonier",
          no: "07",
          code: "1",
          flag: "US",
          favorite: true,
          probability: "89%",
        },
        fighter2: {
          name: "Rodrigues",
          no: "NR",
          code: "2",
          flag: "BR",
          favorite: false,
          probability: "11%",
        },
      },
      {
        id: "2",
        pool: "B",
        weight: "Featherweight",
        eventType: "",
        fighter1: {
          name: "Kattar",
          no: "10",
          code: "3",
          flag: "US",
          favorite: true,
          probability: "64%",
        },
        fighter2: {
          name: "Zalal",
          no: "NR",
          code: "4",
          flag: "MA",
          favorite: false,
          probability: "36%",
        },
      },
      {
        id: "3",
        pool: "C",
        weight: "Middleweight",
        eventType: "",
        fighter1: {
          name: "Shahbazyan",
          no: "NR",
          code: "5",
          flag: "US",
          favorite: false,
          probability: "42%",
        },
        fighter2: {
          name: "Budka",
          no: "NR",
          code: "6",
          flag: "US",
          favorite: true,
          probability: "58%",
        },
      },
      {
        id: "4",
        pool: "D",
        weight: "Lightweight",
        eventType: "",
        fighter1: {
          name: "Bonfim",
          no: "NR",
          code: "7",
          flag: "BR",
          favorite: false,
          probability: "31%",
        },
        fighter2: {
          name: "Sadykhov",
          no: "NR",
          code: "8",
          flag: "AZ",
          favorite: true,
          probability: "69%",
        },
      },
    ]
  }, {
    title: "ROUND 2 - UFC FIGHT NIGHT",
    when: "Sun Feb 23 2AM UTC",
    tooltipText: "ROUND COMPLETED",
    index: 3,
    participants: [
      {
        id: "1",
        pool: "A",
        weight: "Bantamweight",
        eventType: "main event",
        fighter1: {
          name: "Cejudo",
          no: "07",
          code: "1",
          flag: "US",
          favorite: true,
          probability: "89%",
        },
        fighter2: {
          name: "Yadong",
          no: "08",
          code: "2",
          flag: "CN",
          favorite: false,
          probability: "11%",
        },
      },
      {
        id: "2",
        pool: "B",
        weight: "Middleweight",
        eventType: "",
        fighter1: {
          name: "Allen",
          no: "09",
          code: "3",
          flag: "US",
          favorite: true,
          probability: "64%",
        },
        fighter2: {
          name: "Hernandez",
          no: "12",
          code: "4",
          flag: "US",
          favorite: false,
          probability: "36%",
        },
      },
      {
        id: "3",
        pool: "C",
        weight: "Catchweight",
        eventType: "",
        fighter1: {
          name: "Font",
          no: "09",
          code: "5",
          flag: "PR",
          favorite: false,
          probability: "42%",
        },
        fighter2: {
          name: "MATSUMOTO",
          no: "NR",
          code: "6",
          flag: "BR",
          favorite: true,
          probability: "58%",
        },
      },
      {
        id: "4",
        pool: "D",
        weight: "Featherweight",
        eventType: "",
        fighter1: {
          name: "Silva",
          no: "NR",
          code: "7",
          flag: "BR",
          favorite: false,
          probability: "31%",
        },
        fighter2: {
          name: "Baghdasaryan",
          no: "NR",
          code: "8",
          flag: "AM",
          favorite: true,
          probability: "69%",
        },
      },
    ]
  },
  {
    title: "ROUND 3 - UFC FIGHT NIGHT",
    when: "Sun Mar 2 12AM UTC",
    tooltipText: "ROUND COMPLETED",
    index: 4,
    participants: [
      {
        id: "1",
        pool: "A",
        weight: "Flyweight",
        eventType: "main event",
        fighter1: {
          name: "Kape",
          no: "06",
          code: "1",
          flag: "PT",
          favorite: true,
          probability: "89%",
        },
        fighter2: {
          name: "Almabayev",
          no: "08",
          code: "2",
          flag: "KZ",
          favorite: false,
          probability: "11%",
        },
      },
      {
        id: "2",
        pool: "B",
        weight: "Middleweight",
        eventType: "",
        fighter1: {
          name: "Brundage",
          no: "NR",
          code: "3",
          flag: "US",
          favorite: true,
          probability: "64%",
        },
        fighter2: {
          name: "Marquez",
          no: "NR",
          code: "4",
          flag: "US",
          favorite: false,
          probability: "36%",
        },
      },
      {
        id: "3",
        pool: "C",
        weight: "Lightweight",
        eventType: "",
        fighter1: {
          name: "Haqparast",
          no: "NR",
          code: "5",
          flag: "MA",
          favorite: false,
          probability: "42%",
        },
        fighter2: {
          name: "Ribovics",
          no: "NR",
          code: "6",
          flag: "AR",
          favorite: true,
          probability: "58%",
        },
      },
      {
        id: "4",
        pool: "D",
        weight: "Featherweight",
        eventType: "",
        fighter1: {
          name: "Amil",
          no: "NR",
          code: "7",
          flag: "PH",
          favorite: false,
          probability: "31%",
        },
        fighter2: {
          name: "Gomis",
          no: "NR",
          code: "8",
          flag: "FR",
          favorite: true,
          probability: "69%",
        },
      },
    ]
  },

  {
    title: "ROUND 4 - UFC 313",
    when: "Sun Mar 9 3AM UTC",
    tooltipText: "ROUND COMPLETED",
    index: 5,
    participants: [
      {
        id: "1",
        pool: "A",
        weight: "Light Heavyweight",
        eventType: "main event",
        fighter1: {
          name: "Pereira",
          no: "C",
          code: "1",
          flag: "BR",
          favorite: true,
          probability: "89%",
        },
        fighter2: {
          name: "Ankalaev",
          no: "01",
          code: "2",
          flag: "RU",
          favorite: false,
          probability: "11%",
        },
      },
      {
        id: "2",
        pool: "B",
        weight: "Lightweight",
        eventType: "",
        fighter1: {
          name: "Gaethje",
          no: "03",
          code: "3",
          flag: "US",
          favorite: true,
          probability: "64%",
        },
        fighter2: {
          name: "Fiziev",
          no: "11",
          code: "4",
          flag: "AZ",
          favorite: false,
          probability: "36%",
        },
      },
      {
        id: "3",
        pool: "C",
        weight: "Lightweight",
        eventType: "",
        fighter1: {
          name: "Turner",
          no: "13",
          code: "5",
          flag: "US",
          favorite: false,
          probability: "42%",
        },
        fighter2: {
          name: "Bahamondes",
          no: "NR",
          code: "6",
          flag: "CL",
          favorite: true,
          probability: "58%",
        },
      },
      {
        id: "4",
        pool: "D",
        weight: "Women Strawweight",
        eventType: "",
        fighter1: {
          name: "Lemos",
          no: "05",
          code: "7",
          flag: "BR",
          favorite: false,
          probability: "31%",
        },
        fighter2: {
          name: "Lucindo",
          no: "07",
          code: "8",
          flag: "BR",
          favorite: true,
          probability: "69%",
        },
      },
    ]
  }, {
    title: "ROUND 5 - UFC FIGHT NIGHT",
    when: "Sun Mar 16 11PM UTC",
    tooltipText: "ROUND COMPLETED",
    index: 6,
    participants: [
      {
        id: "1",
        pool: "A",
        weight: "Middleweight",
        eventType: "main event",
        fighter1: {
          name: "Vettori",
          no: "08",
          code: "0",
          flag: "IT",
          favorite: true,
          probability: "89%",
        },
        fighter2: {
          name: "Dolidze",
          no: "11",
          code: "2",
          flag: "GE",
          favorite: false,
          probability: "11%",
        },
      },
      {
        id: "2",
        pool: "B",
        weight: "Welterweight",
        eventType: "",
        fighter1: {
          name: "Njokuani",
          no: "NR",
          code: "3",
          flag: "US",
          favorite: true,
          probability: "64%",
        },
        fighter2: {
          name: "Dos Santos",
          no: "NR",
          code: "4",
          flag: "BR",
          favorite: false,
          probability: "36%",
        },
      },
      {
        id: "3",
        pool: "C",
        weight: "Featherweight",
        eventType: "",
        fighter1: {
          name: "Choi",
          no: "NR",
          code: "5",
          flag: "KR",
          favorite: false,
          probability: "42%",
        },
        fighter2: {
          name: "Vallejos",
          no: "NR",
          code: "6",
          flag: "AR",
          favorite: true,
          probability: "58%",
        },
      },
      {
        id: "4",
        pool: "D",
        weight: "Lightweight",
        eventType: "",
        fighter1: {
          name: "Hernandez",
          no: "NR",
          code: "7",
          flag: "US",
          favorite: false,
          probability: "31%",
        },
        fighter2: {
          name: "Holobaugh",
          no: "NR",
          code: "8",
          flag: "US",
          favorite: true,
          probability: "69%",
        },
      },
    ]
  }, {
    title: "ROUND 6 - UFC FIGHT NIGHT",
    when: "Sat Mar 22 8PM UTC",
    tooltipText: "ROUND COMPLETED",
    index: 7,
    participants: [
      {
        id: "1",
        pool: "A",
        weight: "Welterweight",
        eventType: "main event",
        fighter1: {
          name: "Edwards",
          no: "01",
          code: "0",
          flag: "GB",
          favorite: true,
          probability: "89%",
        },
        fighter2: {
          name: "Brady",
          no: "05",
          code: "2",
          flag: "US",
          favorite: false,
          probability: "11%",
        },
      },
      {
        id: "2",
        pool: "B",
        weight: "Welterweight",
        eventType: "",
        fighter1: {
          name: "Nelson",
          no: "NR",
          code: "3",
          flag: "IS",
          favorite: true,
          probability: "64%",
        },
        fighter2: {
          name: "Holland",
          no: "NR",
          code: "4",
          flag: "US",
          favorite: false,
          probability: "36%",
        },
      },
      {
        id: "3",
        pool: "C",
        weight: "Women Strawweight",
        eventType: "",
        fighter1: {
          name: "McCann",
          no: "NR",
          code: "5",
          flag: "GB",
          favorite: false,
          probability: "42%",
        },
        fighter2: {
          name: "Thainara",
          no: "NR",
          code: "6",
          flag: "BR",
          favorite: true,
          probability: "58%",
        },
      },
      {
        id: "4",
        pool: "D",
        weight: "Featherweight",
        eventType: "",
        fighter1: {
          name: "Wood",
          no: "NR",
          code: "7",
          flag: "GB",
          favorite: false,
          probability: "31%",
        },
        fighter2: {
          name: "Charriere",
          no: "NR",
          code: "8",
          flag: "FR",
          favorite: true,
          probability: "69%",
        },
      },
    ]
  },
  {
    title: "ROUND 7 - UFC FIGHT NIGHT",
    when: "Sat Mar 29 11PM UTC",
    tooltipText: "ROUND COMPLETED",
    index: 8,
    participants: [
      {
        id: "1",
        pool: "A",
        weight: "Flyweight",
        eventType: "main event",
        fighter1: {
          name: "Moreno",
          no: "02",
          code: "0",
          flag: "MX",
          favorite: true,
          probability: "89%",
        },
        fighter2: {
          name: "Erceg",
          no: "08",
          code: "2",
          flag: "AU",
          favorite: false,
          probability: "11%",
        },
      },
      {
        id: "2",
        pool: "B",
        weight: "Lightweight",
        eventType: "",
        fighter1: {
          name: "Torres",
          no: "NR",
          code: "3",
          flag: "MX",
          favorite: true,
          probability: "64%",
        },
        fighter2: {
          name: "Dober",
          no: "NR",
          code: "4",
          flag: "US",
          favorite: false,
          probability: "36%",
        },
      },
      {
        id: "3",
        pool: "C",
        weight: "Middleweight",
        eventType: "",
        fighter1: {
          name: "Gastelum",
          no: "NR",
          code: "5",
          flag: "US",
          favorite: false,
          probability: "42%",
        },
        fighter2: {
          name: "Pyfer",
          no: "NR",
          code: "6",
          flag: "US",
          favorite: true,
          probability: "58%",
        },
      },
      {
        id: "4",
        pool: "D",
        weight: "Flyweight",
        eventType: "",
        fighter1: {
          name: "Rodriguez",
          no: "NR",
          code: "7",
          flag: "MX",
          favorite: false,
          probability: "31%",
        },
        fighter2: {
          name: "Borjas",
          no: "NR",
          code: "8",
          flag: "PE",
          favorite: true,
          probability: "69%",
        },
      },
    ]
  }, {
    title: "ROUND 8 - UFC 311",
    when: "6 Apr 2AM UTC",
    tooltipText: "ROUND INCOMING",
    index: 0,
    participants: []
  },
  {
    title: "ROUND 9 - UFC 314",
    when: "13 Apr 3AM UTC",
    tooltipText: "ROUND INCOMING",
    index: 0,
    participants: []
  },

];