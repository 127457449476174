const SideConnected = ({ address, shortedAddress, setOpen }) => {
    return (
        <>
            <div className="header__inner-extra">
                <div className="header__inner-extra-text">
                    <p>{shortedAddress(address)}</p>
                </div>
            </div>
            <button
                type="button"
                className="header__inner-connect"
                onClick={() => setOpen(true)}
            >
                <div className="header__inner-connect-inner">Disconnect</div>
            </button>
        </>
    );
}

export default SideConnected;