import { Fragment, useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Header from "./Header";
import { AnimatePresence } from "framer-motion";
import Modal from "./Modal";
import ConnectModal from "./Modals/ConnectModal";
import PayModal from "./Modals/PayModal";
import CongratsModal from "./Modals/CongratsModal";
import ModalS from "./ModalS";
import Started from "./Modals/Started";
import PremiumMod from "./Modals/PremiumMod";
import FirstContextProvider from "../Context/FirstContext";
import F1 from "../Pages/F1";
import MMA from "../Pages/MMA";
import Crypto from "../Pages/Crypto";
// import Defi from "../Pages/Defi/Defi";

function Main() {
  const [menu, setMenu] = useState(false);
  const [modal, setModal] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [connected, setConnected] = useState(false);
  const location = useLocation();
  const [pass, setPass] = useState({
    formula1: false,
    mma: false,
    crypto: false,
  });
  const updatePass = (data) => {
    setPass((pass) => ({ ...pass, ...data }));
  };
  const close = (e) => {
    if (e.target === e.currentTarget) setMenu(false);
  };
  useEffect(() => {
    if (menu && modal) {
      document.body.classList.add("active");
    } else if (menu) {
      document.body.classList.add("active");
    } else if (modal) {
      document.body.classList.add("active");
    } else {
      document.body.classList.remove("active");
    }
  }, [menu, modal]);
  useEffect(() => {
    window.scrollTo(0, 0);
    setModal(null);
    setMenu(false);
  }, [location]);

  return (
    <>
      <Fragment>
        <FirstContextProvider>
          <Header
            menu={menu}
            setMenu={setMenu}
            setModal={setModal}
            connected={connected}
            setConnected={setConnected}
          />
          <Routes>
            <Route
              path="*"
                element={
                  <MMA
                    menu={menu}
                    setMenu={setMenu}
                    modal={modal}
                    setModal={setModal}
                    connected={connected}
                    setConnected={setConnected}
                    close={close}
                    pass={pass}
                  />
                }
              
            />
            <Route
              path="/f1"
              element={
                <F1
                  menu={menu}
                  setMenu={setMenu}
                  modal={modal}
                  setModal={setModal}
                  connected={connected}
                  setConnected={setConnected}
                  close={close}
                  pass={pass}
                  />
              }
            />
            <Route
              path="/crypto"
              element={
                <Crypto
                  menu={menu}
                  setMenu={setMenu}
                  modal={modal}
                  setModal={setModal}
                  connected={connected}
                  setConnected={setConnected}
                  close={close}
                  pass={pass}
                />
              }
            />
            {/* <Route
              path="/defi"
              element={
                <Defi
                  menu={menu}
                  setMenu={setMenu}
                  setModal={setModal}
                  modal={modal}
                  connected={connected}
                  setConnected={setConnected}
                  close={close}
                />
              }
            /> */}
          </Routes>
        </FirstContextProvider>
      </Fragment>
      <AnimatePresence>
        {modal === "connect" && (
          <Modal addClass={"addClass"} setModal={setModal}>
            <ConnectModal setModal={setModal} />
          </Modal>
        )}
        {modal === "pay" && (
          <Modal addClass={"addClass"} setModal={setModal}>
            <PayModal setModal={setModal} modalData={modalData} />
          </Modal>
        )}
        {modal === "congrats" && (
          <Modal setModal={setModal}>
            <CongratsModal
              updatePass={updatePass}
              setModal={setModal}
              modalData={modalData}
              setModalData={setModalData}
            />
          </Modal>
        )}
        {modal === "started" && (
          <ModalS setModal={setModal}>
            <Started setModal={setModal} />
          </ModalS>
        )}
        {modal === "premium" && (
          <Modal addClass={"premium"} addClassMod="nopd" setModal={setModal}>
            <PremiumMod
              pass={pass}
              setModal={setModal}
              setModalData={setModalData}
            />
          </Modal>
        )}
      </AnimatePresence>
    </>
  );
}

export default Main;
