/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useEffect } from 'react';
import dualAbi from "../Abi/dual.json";
import multicallAbi from "../Abi/multicall.json";
// import axios from 'axios'

import { createPublicClient, http, encodeFunctionData, decodeFunctionResult, prepareEncodeFunctionData } from 'viem'
import { abstractTestnet } from "viem/chains";
import { getGeneralPaymasterInput } from "viem/zksync";

import {
    useWriteContractSponsored,
} from "@abstract-foundation/agw-react";
import { useAccount, useWalletClient } from "wagmi";
import { config } from "../Components/WagmiConfig"

const publicClient = createPublicClient({
    chain: abstractTestnet,
    transport: http(),
})

/* -------------------------------------------------------------------------- */
/*                                    UTILS                                   */
/* -------------------------------------------------------------------------- */

const MULTICALL_BATCHES_LIMIT = 200;

// const txOptions = { gasLimit: "" };

const CATEGORIES_DATA = [{
    name: "MMA",
    passName: "mma",
    predCount: 4,
    category: 0
}, {
    name: "F1",
    passName: "formula1",
    predCount: 10,
    category: 1
}, {
    name: "CRYPTO",
    passName: "crypto",
    predCount: 10, // to define?
    category: 2
}]


const allowedNetworks = [
    // // "0xa4b1", // arbitrum
    // "0x7a69", // localhost
    // "0x2b74", // localhost abstract
    // // "0x153e6923d4b", // dao testnet
    11124, // abstract testnet
    31337, // localhost
]

// const txOptions = { gasLimit: "1000000" };


const shortedAddress = address => {
    const len = address.length
    const start = address.slice(0, 4)
    const end = address.slice(len - 4, len)
    return start + "..." + end
}

export const FirstContext = createContext()

const FirstContextProvider = (props) => {
    const { address, isConnecting, isConnected, isDisconnected, chainId, connector } = useAccount();

    const { writeContractSponsored } = useWriteContractSponsored();

    const { data: walletClient } = useWalletClient({ config });


    /* -------------------------------------------------------------------------- */
    /*                                  USE STATE                                 */
    /* -------------------------------------------------------------------------- */

    const [sessionsCount, setSessionsCount] = useState(0);
    const [txCount, setTxCount] = useState(0);
    const [dualSessions, setDualSessions] = useState([]);
    const [playersList, setPlayersList] = useState([]);
    const [playerPositions, setPlayerPositions] = useState([]);
    const [playerRewards, setPlayerRewards] = useState([]);
    const [abstractWallet, setAbstractWallet] = useState(false);
    const [openMessage, setOpenMessage] = useState(false);
    const [openSuccessMessage, setOpenSuccessMessage] = useState(false);
    const [openErrorMessage, setOpenErrorMessage] = useState(false);
    const [openBackdrop, setOpenBackdrop] = useState(false);


    const [allowedNetwork, setAllowedNetwork] = useState(true);

    /* -------------------------------------------------------------------------- */
    /*                                 USE EFFECT                                 */
    /* -------------------------------------------------------------------------- */

    useEffect(() => {
        getDualData();
    }, [txCount, sessionsCount]);

    useEffect(() => {
        if (dualSessions.length === 0) return;
        getPlayersList();
    }, [dualSessions]);

    useEffect(() => {
        if (playersList.length === 0) return;
        getPlayerPositions();
        getPlayerRewards();
    }, [playersList])

    useEffect(() => {
        if (!connector) return setAbstractWallet(false);
        if (connector?.id === "xyz.abs.privy") return setAbstractWallet(true);
    }, [connector]);

    useEffect(() => {
        if (!isConnected || !chainId) return;
        setAllowedNetwork(allowedNetworks.includes(chainId));
    }, [chainId, isConnected]);

    useEffect(() => {
        if (!allowedNetwork) return setOpenMessage(true);
        setOpenMessage(false);
    }, [allowedNetwork])

    /* -------------------------------------------------------------------------- */
    /*                                    UTILS                                   */
    /* -------------------------------------------------------------------------- */


    const getDualData = async () => {
        try {
            const fetchedSessionCount = await getDualSessionsCount();
            if (fetchedSessionCount === 0) return;

            setSessionsCount(fetchedSessionCount);
            await getDualSessions();
        } catch (e) {
            console.log(e.message);
        }
    }

    const getDualSessionsCount = async () => {
        try {
            const count = await publicClient.readContract({
                address: process.env.REACT_APP_DUAL_MODE,
                abi: dualAbi,
                functionName: 'getSessionsCount',
            })
            return parseInt(count);
        } catch (e) {
            console.log(e.message);
            return 0;
        }
    }

    const getDualSessions = async () => {
        try {
            if (sessionsCount === 0) return setDualSessions([]);

            const getSessionName = "getSession";

            const targetList = [];
            const dataList = [];
            const functionNameList = [];

            const preparedData = prepareEncodeFunctionData({
                abi: dualAbi,
                functionName: getSessionName
            });

            for (let i = 0; i < sessionsCount; i++) {
                targetList.push(process.env.REACT_APP_DUAL_MODE);

                dataList.push(encodeFunctionData({
                    ...preparedData,
                    args: [i]
                }));

                functionNameList.push(getSessionName);
            }


            const results = await decodedFunctionResults(targetList, dataList, functionNameList);

            setDualSessions(results);
        } catch (e) {
            console.log(e.message);
            setDualSessions([]);
        }
    }


    const getPlayersList = async () => {
        try {
            if (dualSessions.length === 0) return setPlayersList([]);

            const playersLengthList = await getPlayersLengthList();

            if (playersLengthList.length === 0) return setPlayersList([]);

            const functionName = "getSessionPlayer";

            const preparedData = prepareEncodeFunctionData({
                abi: dualAbi,
                functionName
            });

            const results = []

            for (let i = 0; i < playersLengthList.length; i++) {
                const targetList = [];
                const dataList = [];
                const functionNameList = [];

                for (let j = 0; j < parseInt(playersLengthList[i]); j++) {
                    targetList.push(process.env.REACT_APP_DUAL_MODE);

                    dataList.push(encodeFunctionData({
                        ...preparedData,
                        args: [i, j]
                    }));

                    functionNameList.push(functionName);
                }

                const partialResults = await decodedFunctionResults(targetList, dataList, functionNameList);
                results.push(partialResults);
            }

            setPlayersList(results);
        } catch (e) {
            console.log(e.message);
            setPlayersList([]);
        }
    }

    const getPlayersLengthList = async () => {
        try {
            if (dualSessions.length === 0) return [];

            const functionName = "getSessionPlayersLength"

            const preparedData = prepareEncodeFunctionData({
                abi: dualAbi,
                functionName
            });

            const targetList = [];
            const dataList = [];
            const functionNameList = [];

            for (let i = 0; i < dualSessions.length; i++) {
                targetList.push(process.env.REACT_APP_DUAL_MODE);

                dataList.push(encodeFunctionData({
                    ...preparedData,
                    args: [i]
                }));

                functionNameList.push(functionName);
            }

            return await decodedFunctionResults(targetList, dataList, functionNameList);
        } catch (e) {
            console.log(e.message);
            return [];
        }
    }

    const getPlayerPositions = async () => {
        try {
            if (playersList.length === 0) return setPlayerPositions([]);

            const functionName = "getPlayerPosition"

            const preparedData = prepareEncodeFunctionData({
                abi: dualAbi,
                functionName
            });

            const results = [];

            for (let i = 0; i < playersList.length; i++) {
                const targetList = [];
                const dataList = [];
                const functionNameList = [];


                for (let j = 0; j < playersList[i].length; j++) {
                    targetList.push(process.env.REACT_APP_DUAL_MODE);

                    dataList.push(encodeFunctionData({
                        ...preparedData,
                        args: [i, playersList[i][j]]
                    }));

                    functionNameList.push(functionName);

                }

                const patialResults = await decodedFunctionResults(targetList, dataList, functionNameList);
                results.push(patialResults);
            }


            setPlayerPositions(results);
        } catch (e) {
            console.log(e.message);
            setPlayerPositions([]);
        }
    }

    const getPlayerRewards = async () => {
        try {
            if (playersList === 0) setPlayerRewards([]);

            const functionName = "getPlayerRewards"

            const preparedData = prepareEncodeFunctionData({
                abi: dualAbi,
                functionName
            });

            const results = [];
            for (let i = 0; i < playersList.length; i++) {
                const targetList = [];
                const dataList = [];
                const functionNameList = [];

                for (let j = 0; j < playersList[i].length; j++) {
                    targetList.push(process.env.REACT_APP_DUAL_MODE);

                    dataList.push(encodeFunctionData({
                        ...preparedData,
                        args: [i, playersList[i][j]]
                    }));

                    functionNameList.push(functionName);
                }

                const patialResults = await decodedFunctionResults(targetList, dataList, functionNameList);
                results.push(patialResults);
            }

            setPlayerRewards(results);
        } catch (e) {
            console.log(e.message);
            setPlayerRewards([]);
        }
    }

    const decodedFunctionResults = async (targetList, dataList, functionNameList) => {
        try {
            if (targetList.length === 0) return [];
            const encodedResult = await splitBatches(targetList, dataList);
            const results = [];
            for (let i = 0; i < encodedResult.length; i++) {
                const partialResults = decodeFunctionResult({
                    abi: dualAbi,
                    functionName: functionNameList[i],
                    data: encodedResult[i]
                });

                results.push(partialResults);
            }

            return results;
        } catch (e) {
            console.log(e.message);
            return [];
        }
    }

    const splitBatches = async (targetList, dataList) => {
        try {
            if (dataList.length <= MULTICALL_BATCHES_LIMIT) {
                return await fetchMulticall(targetList, dataList)
            } else {
                let results = [];
                let offset = 0;

                const fullBatches = parseInt(targetList.length / MULTICALL_BATCHES_LIMIT);

                for (let i = 0; i < fullBatches; i++) {
                    const partialResults = await fetchMulticall(targetList.slice(offset, offset + MULTICALL_BATCHES_LIMIT), dataList.slice(offset, offset + MULTICALL_BATCHES_LIMIT));
                    results.concat(partialResults);
                    offset += MULTICALL_BATCHES_LIMIT;
                }

                const remainingResultsCount = targetList.length % MULTICALL_BATCHES_LIMIT;
                if (remainingResultsCount > 0) {
                    const remainingResults = await fetchMulticall(targetList.slice(offset, offset + remainingResultsCount), dataList.slice(offset, offset + remainingResultsCount));
                    results.concat(remainingResults);
                }

                return results;
            }
        } catch (e) {
            console.log(e.message);
            return [];
        }
    }

    const fetchMulticall = async (targetList, dataList) => {
        const res = await publicClient.readContract({
            address: process.env.REACT_APP_MULTICALL,
            abi: multicallAbi,
            functionName: 'multiCall',
            args: [targetList, dataList]
        })

        return res;
    }

    const getStaticCategoryData = categoryName => {
        const result = CATEGORIES_DATA.filter(el => el.name === categoryName);
        if (result.length > 0) return result[0];
        else return [];
    }

    const filterOnChainDataByCategory = category => {
        if (!dualSessions) return [];
        if (dualSessions.length === 0) return [];
        return dualSessions.filter(el => parseInt(el?.category) === category)
    }


    const filterUserChoices = (userAddress, predCount) => {
        if (!userAddress) return [];
        if (playerPositions.length === 0) return [];
        if (playerPositions.length !== playersList.length) return [];

        const arr = [];
        for (let i = 0; i < playerPositions.length; i++) {
            if (playerPositions[i].length !== playersList[i].length) return [];
            for (let j = 0; j < playerPositions[i].length; j++) {
                if (playersList[i][j].toLowerCase() === userAddress.toLowerCase()) {
                    arr.push(playerPositions[i][j].choices);
                    break;
                }
            }

            if (arr.length === i) arr.push([])
        }


        return arr;
    }


    const bet = async (active, alreadyPlayed, choices) => {
        const now = parseInt(new Date() / 1000);
        if (
            isConnected &&
            allowedNetwork &&
            sessionsCount > 0 &&
            dualSessions.length > 0 &&
            active >= 0 &&
            active < dualSessions.length &&
            !alreadyPlayed &&
            choices.length === parseInt(dualSessions[active]?.predCount) &&
            choices.every(el => typeof el === "boolean") &&
            now < parseInt(dualSessions[active]?.lock)
        ) {
            try {
                setOpenBackdrop(true)
                const commom = {
                    abi: dualAbi,
                    address: process.env.REACT_APP_DUAL_MODE,
                    functionName: 'bet',
                    args: [active, choices, []]
                }

                if (!abstractWallet) {
                    const { request } = await publicClient.simulateContract({
                        ...commom,
                        account: address
                    })

                    const hash = await walletClient.writeContract(request);
                    const receipt = await publicClient.waitForTransactionReceipt({ hash });

                    setOpenBackdrop(false);

                    if (receipt.status === "success" || receipt.status === 1) {
                        setOpenSuccessMessage(true);
                        setTxCount(txCount + 1);
                    } else {
                        setOpenErrorMessage(true)
                    }

                } else {
                    writeContractSponsored({
                        ...commom,
                        paymaster: process.env.REACT_APP_PAYMASTER,
                        paymasterInput: getGeneralPaymasterInput({
                            innerInput: "0x",
                        })
                    })

                    setOpenBackdrop(false);
                    setTxCount(txCount + 1);
                }

            } catch (e) {
                console.log(e.message)
                setOpenBackdrop(false)
                setOpenErrorMessage(true)
            }

        }
    }

    /* -------------------------------------------------------------------------- */
    /*                              CONTEXT VARIABLES                             */
    /* -------------------------------------------------------------------------- */

    return (
        <FirstContext.Provider value={{
            getStaticCategoryData,
            filterOnChainDataByCategory,
            shortedAddress,
            sessionsCount,
            txCount,
            dualSessions,
            playersList,
            playerPositions,
            playerRewards,
            address,
            isConnecting,
            isConnected,
            isDisconnected,
            chainId,
            openMessage,
            setOpenMessage,
            openSuccessMessage,
            setOpenSuccessMessage,
            openErrorMessage,
            setOpenErrorMessage,
            filterUserChoices,
            openBackdrop,
            setOpenBackdrop,
            bet
        }}>
            {props.children}
        </FirstContext.Provider>
    )
}

export default FirstContextProvider